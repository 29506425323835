import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

const web = lazy(() => import('./web/App'));
const smartTv = lazy(() => import('./smartTv/App'));

const AppFolderName =
  process.env.REACT_APP_UEFATV_PLATFORM === 'web' ? 'web' : 'smartTv';
const AppType = process.env.REACT_APP_UEFATV_PLATFORM === 'web' ? web : smartTv;

const App = () => (
  <Suspense fallback={<div />}>
    <AppType />
  </Suspense>
);

ReactDOM.render(<App />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept(`./${AppFolderName}/App`, () => {
    ReactDOM.render(<App />, document.getElementById('root'));
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
